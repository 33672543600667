html, body{
  box-sizing: border-box;
}

h1{
  color: #ce5978;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.1;   

}

h2{
  color: rgba(0,0,0,0.7);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1;    
}

h2 small{
  color: rgba(0,0,0,0.5);
 
}

h3{
  color: rgba(0,0,0,0.6);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.1;    
}

a{
  text-decoration: none;
  color: #3c8dbc;

}

a:hover{
  color: #337ab7;

}

.App {
  text-align: left;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: rgba(0,0,0,0.1);
}
.appBar{
  margin-bottom: 10px;
}

.appBar.MuiAppBar-colorPrimary{
  background-color: #fff;
  color:rgba(0,0,0,0.8);
}

.appBar.MuiAppBar-colorPrimary h2{
  color:rgba(0,0,0,0.8);

}

.centerText{
  text-align: center;
}


.formTitle{
  font-size: 1.3rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0095em; 
  padding-bottom: 10px; 
}

.formSubTitle{
  font-size: 1.1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0098em; 
  padding-bottom: 5px; 
  padding-top: 15px; 
}

.formSubTitle-2{
  font-size: 1.0rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  letter-spacing: 0.03em; 
  padding-bottom: 2px; 
}

.formSection{
  font-size: 1.05em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0095em;  
}

.formLabel{
  font-size: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.01em;  
  color: rgba(0,0,0,0.9); 
  margin-bottom: 20px;  
}

.sectionDescription{
  color: rgba(0,0,0,0.6);
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4em;
  letter-spacing: 0.02em;  
}

.sectionInfoDescription{
  color: rgba(0,0,0,0.6);
  font-size: 0.9em;    

}

.captionText{
  color: rgba(0,0,0,0.6);
  font-size: 0.7em;    

}

.error{
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4em;
  letter-spacing: 0.02em;  
  color: rgba(199, 0, 0, 0.829);
}
.drp_picker{
  float:right;
}

.drp_picker .DateInput_input{
  box-sizing: border-box;
  font-size: 15px;
}

.drp_picker .DateInput{
  width: 120px;
}

.drp_picker .DateRangePickerInput_arrow_svg{
  width: 14px;
  height: 14px;
}


.MuiButton-containedPrimary.rv-eng-next-button, .MuiButton-containedPrimary.rv-eng-save-button{
  margin-top: 20px;
  margin-bottom: 20px;
}

.MuiButton-containedPrimary.rv-eng-save-button{
  background-color: rgb(145,215, 104);
}

.MuiButton-containedPrimary.rv-eng-save-button:active, .MuiButton-containedPrimary.rv-eng-save-button:hover{
  background-color: rgba(145,215, 104, 0.8);
}

.drpReportPicker .DateInput{
  position: static;
  width: 188px;
}
.drpReportPicker .DateInput_input{
  width: 150px;
  text-align: center;
  font-size: 16px;
}



.DateRangePicker_picker{
  z-index: 9999;
}

.sngReportPicker .DateInput{
  position: static;
  width: 188px;
}
.sngReportPicker .DateInput_input{
  width: 165px;
  text-align: center;
  font-size: 16px;
}



.reports .title{
  font-size: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0098em; 
  padding-bottom: 5px; 
  padding-top: 15px; 
}


.reports .description{
  color: rgba(0,0,0,0.6);
  font-size: 0.7em;    
  margin-top: 10px;
  margin-bottom: 10px;

}




.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2{
  display:block;
} 
.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2:after{
  clear: both;
} 

.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2 .rvButtonLeft{
  padding: 20px 20px;
  display:block;
  float: left;

}
.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontal.DayPickerNavigation_button__horizontal_2 .rvButtonRight{
  padding: 20px 20px;
  display:block;
  float: right;

}

.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2{
  display:block;
  margin-bottom: 20px;;
} 
.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2:after{
  clear: both;
  
} 

.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2 .rvButtonLeft{
  padding: 20px 20px;
  display:block;
  float: left;

}
.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__vertical.DayPickerNavigation_button__vertical_2 .rvButtonRight{
  padding: 20px 20px;
  display:block;
  float: right;

}


