.rvNS{

  $spacer: 16px;
  $breakpoint_xs: 600px;
  $breakpoint_sm: 960px;
  $breakpoint_md: 1280px;
  $breakpoint_lg: 1920px;

  .mb-1{
    margin-bottom: $spacer * 1;
  }
  .mb-2{
    margin-bottom: $spacer * 2;
  }
  .mb-3{
    margin-bottom: $spacer * 3;
  }
  .mb-4{
    margin-bottom: $spacer * 4;
  } 
  .mb-5{
    margin-bottom: $spacer * 5;
  } 
  
  .mt-1{
    margin-top: $spacer * 1;
  }
  .mt-2{
    margin-top: $spacer * 2;
  }
  .mt-3{
    margin-top: $spacer * 3;
  }
  .mt-4{
    margin-top: $spacer * 4;
  } 
  .mt-5{
    margin-top: $spacer * 5;
  } 

  @media (max-width: $breakpoint_xs) {
    .mb-xs-1{
      margin-bottom: $spacer * 1;
    }
    .mb-xs-2{
      margin-bottom: $spacer * 2;
    } 
    .mb-xs-3{
      margin-bottom: $spacer * 3;
    } 
    .mb-xs-4{
      margin-bottom: $spacer * 4;
    }     

  }

  @media (min-width: $breakpoint_xs) and (max-width: $breakpoint_sm) {
    .mb-sm-1{
      margin-bottom: $spacer * 1;
    }
    .mb-sm-2{
      margin-bottom: $spacer * 2;
    } 
    .mb-sm-3{
      margin-bottom: $spacer * 3;
    } 
    .mb-sm-4{
      margin-bottom: $spacer * 4;
    }     

  }

  .overlay{
    background-color: rgba(0,0,0,0.9);
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
  .overlay .upload{
    position: relative;
    color: #fff;
    font-size: 1.4em;
    text-align: center;
    margin-top:140px;
  }
  .overlay .progress{
    position: relative;
    margin: auto;
    width: 350px; 
    background-color: #000;
    height: 10px;
    margin-top: 30px;
  }
  .overlay .progress .inner{
    position: relative;
    width: 0px;
    height: 100%;
    background-color: #fff;
  }

  .overlay .progress .inner.step-0{
    width: 10%;
  }


  .video-processing{
    $width: 100%;
    background-color: rgba(0,0,0,0.8);
    position: relative;
    width: $width;
    height: 300px;
  }

  .video-processing .info{
    position: relative;
    color: #fff;
    font-size: 1.2em;
    padding: 40px 0px 20px;
    text-align: center;
    text-transform: capitalize;
  }

  .video-processing .info-description{
    position: relative;
    color: #fff;
    font-size: 0.8em;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0px 0px 20px;
    text-align: center;
  }
  .video-processing .icon {
    height: 1.8em;
    color: #fff;
    text-align: center;
  }

  .video-processing .icon .MuiSvgIcon-root{
    font-size: 4em;
  }

}

