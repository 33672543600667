.extension-widget{
    display: block;
    width: 100%;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom:10px;
}

.extension-widget .section{
    font-size: 1.3em;
    font-weight: 600;
    color: rgba(0,0,0,0.7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;  

}

.extension-widget .section .light{
    font-weight: 300;

}

.extension-widget .MuiSvgIcon-root{
    font-size: 1.9em;
}

.extension-widget .description{
    font-size: 0.9em;
    font-weight: 300;
    color: rgba(0,0,0,0.7);
    margin-top: 15px;
    min-height: 60px;

}

.extension-widget .cost{
    font-size: 0.9em;
    font-weight: 300;
    color: rgba(0,0,0,0.7);
    margin-top: 10px;

}

.extension-widget .button{
    margin-top: 15px;
}