    .loading{
        padding-top: 100px;
        text-align: center;
    }

    .top-box{
        padding-top: 80px;
    }

    .fullscreen-panel{
        padding-left: 18px;
        padding-right: 18px;
    }