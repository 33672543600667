    .loading{
        padding-top: 100px;
        text-align: center;
    }

    .top-box{
        padding-top: 80px;
    }

    .customer-section{
        font-weight: 600;
    }
    