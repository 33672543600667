    
    
    .rvQuestionaires
    {
        .loading{
            padding-top: 100px;
            text-align: center;
        }
    
        .top-box{
            padding-top: 80px;
        }
    
        .fullscreen-panel{
            padding-left: 18px;
            padding-right: 18px;
        }
    }


    .rvQuestionairePanel
    {
        .panelGoButton{
            background-color:rgba(83, 165, 42, 0.8);
            color: #fff;
            &:hover{
                background-color:rgba(83, 165, 42, 0.5);
                color: #fff;
            };
            &:disabled{
                background-color:rgba(145,215, 110, 0.4);
                color: #fff;
                cursor: not-allowed;
            };   
        }
        .dialogTitle{
            flex-grow: 1;
            color: #fff;
            font-size: 1.3em;
            margin-top: 0px;            
        }
    }