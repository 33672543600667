

    .rvQEng
    {
        .WidgetBox{
            border: 1px solid rgba(0,0,0,0.3);
            padding: 20px;
        }
        .WidgetContainer{
            padding: 20px 20px;
            background-color: rgba(0,0,0,0.05);
        }
        .typeWidget{
            width: 100%;
        }
        .colItem{
            line-height: 58px;
        }
        .textWidget{
            & input{
                padding-top:21px;
                padding-bottom:21px;
            }
        }
        .options{
            padding-left: 30px;
            padding-right: 30px;
        }


    }