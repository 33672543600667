  body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'monospace';
}

.customer_questionnaire_field{
  padding-top: 10px;
  padding-bottom: 10px;
}

.customer_questionnaire_field label{
  line-height: 1.2em;
}

.customer_questionnaire_field legend{
   margin-bottom: 10px;
   line-height: 1.2em;
}


.snall{
  font-size: 0.6em;
}