

.element{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

}

.extension-widget{
    padding-top: 20px;
    padding-bottom: 20px;

}

.extension-widget .inner{
    padding-left: 20px;
    padding-right: 20px;

}

.extension-widget .widget{
   font-size: 25px;
   color: rgba(0,0,0,0.6);
   display: inline-flex;
   align-self: center;   
}




.extension-widget .widget div{
    margin-top: 4px;
}

.section{
    height: 20px;
    display: block;
    width: 100%;
}

.dash-spacer{
    padding-bottom: 20px;
}

.box{
    height: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    background-color: #fff;
    padding: 18px;
    
}

.red{

    border: 1px solid red;
}

.CardSection{
    margin-top: 10px;
    color: rgba(0,0,0,0.6);
    line-height: 25px;
    
}

.CardSection .cardNumber{
    color: rgba(0,0,0,0.2);
    content: 'Not set';
}

.CardSection .cardNumber:after{
    content: 'Not specfied';
}


.MuiTableHead-root .MuiTableCell-head{
    font-weight: 700;
}


.MuiButton-outlined.space-right{
    margin-right: 5px;
}

.MuiButton-outlined.button-green{
    border-color: rgba(145,215, 104,1);
    color: #318102
}

.MuiButton-outlined.button-green:hover{
    border-color: rgba(145,215, 104,0.6);
}

.MuiButton-contained.space-right{
    margin-right: 5px;
}


.MuiButton-contained.button-green{
    border-color: rgba(145,215, 104,1);
    background-color: rgba(145,215, 104,1);
    color: #fff
}

.MuiButton-contained.button-green:hover{
    border-color: rgba(145,215, 104,0.6);
    background-color: rgba(145,215, 104,0.6);
}