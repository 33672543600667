.rvNSPolicy{
  .sectionTitleL1{
    line-height: 24px;
    font-size: 20px;
  }
  .sectionTitleL2{
    line-height: 22px;
    font-size: 18px;
  } 
  h5.formfield{
    font-size: 14px;
  }
  .caption{
      font-size: 11px;
  } 
  .switchLabel{
    color: rgba(0,0,0,0.6);
  }
  .submit{
      margin-top:30px;
  }
  .area{
      height: 35px;
  }
}

