    .loading{
        padding-top: 100px;
        text-align: center;
    }

    .top-box{
        padding-top: 80px;
    }

    .fullscreen-panel{
        padding-left: 18px;
        padding-right: 18px;
    }


.modal_container .picker_container{
        margin: auto;
}
.DayPicker{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border-radius: 5px;
    padding: 5px;  
    margin-bottom: 10px;
    color: rgba(0,0,0,0.6);
    border: 1px solid rgba(0,0,0,0.2);
    outline: none;
    display: block;

    }

.DayPicker-Months{
    justify-content: space-between;
}    

.DayPicker-Month{
}

.DayPicker.error{
  border: 1px solid rgba(199, 0, 0, 0.829);

}

.box .DayPicker-wrapper {
    outline: none;
  }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #4a90e2;
  color: #f0f8ff;
  outline: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #4688d3;
  color: rgba(0,0,0,0.8);
  outline: none;

}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #4380c7;
  color: rgba(0,0,0,0.8);
  outline: none;
}

.DayPicker-Day--today {
  color: #4380c7;
  font-weight: 700;
  outline: none;
}


.DayPicker-Day.DayPicker-Day--outside{
  color: rgba(0,0,0,0.3);

}

.DayPicker-Day{
  outline: none;
}

.DayPicker-Day--disable{
  color: rgba(0,0,0,0.3);

}

.picker_container{
  margin:auto;
}

.box .DayPicker-NavBar{
    padding-bottom: 5px;
}
.box .react_times_button .preview_container{
  left: 12%;
}
.time_picker_container{
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.time_picker_container .react_times_button {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  cursor: pointer;
  color: #343434;
  border-radius: 2px;
  background-color: #fff;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
   box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 5px;  
  margin-bottom: 10px;
  color: rgba(0,0,0,0.6);


}

.react_times_button.time_picker_preview{
    height: 44px;
}
