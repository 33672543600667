#mui-rte-toolbar{
    border: 1px solid rgba(0,0,0,0.1);
}




.no-files{
    border: 1px dashed rgba(0,0,0,0.1);
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
    color: rgba(0,0,0,0.4);
    font-size: 1.2em;
    text-align:left;
    margin-top: 10px;
    margin-bottom: 10px;
}

.no-files span{
  margin-left: 20px;
}



.dragdroparea{
    border: 4px dashed rgba(0,0,0,0.1);
    display: block;
    min-height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.2em;
    text-align:center;
    margin-top: 10px;
    margin-bottom: 10px;

}

.dragdroparea div{
    color: rgba(0,0,0,0.8);
    margin-top: 10px;
    margin-bottom: 10px;
}

.dragdroparea .inside-drag-area {
    opacity: 0.7;
  }